import MembershipAgreementModal from 'component/Booking/StepsContent/MembershipAgreementModal';
import ButtonPrimary from 'component/Common/ButtonPrimary';
import ButtonSecondary from 'component/Common/ButtonSecondary';
import Leftside from 'component/Leftside';
import MembershipModal from 'component/Memberships/MembershipModal';
import RightContainer from 'component/RightContainer';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { addItemToCart } from 'services/HttpServices';
import DataStore from 'stores/DataStore';
import MembershipsStore from 'stores/MembershipsStore';
import { ItemTypes, MembershipTypes } from 'Utils/constants';
import { formatCurrencyAmount } from 'Utils/globalActions';

const MembershipBenefitPage = () => {
    const { accountConfig, accountInfo } = DataStore;
    const { memberships } = MembershipsStore;
    const routeParams = useParams()
    const id = Number(routeParams.id)

    const selectedMembership = Array.from(memberships.values()).find(membership => membership.id === id)

    const [showSelectFrequencyModal, setShowSelectFrequencyModal] = useState(false)
    const [selectedFrequency, setSelectedFrequency] = useState(null)
    const [showAgreementModal, setShowAgreementModal] = useState(false)

    const navigate = useNavigate()

    const handleAddToCart = (couponCode) => {
        let formData = {
            item_id: selectedMembership.id,
            item_type: ItemTypes.Membership,
            membership_props: {
                frequency: selectedFrequency,
            }
        }
        if(couponCode) {
            formData.membership_props.coupon_code = couponCode
        }
        addItemToCart(formData)
    }

    const handleSelectFrequency = () => {
        setShowSelectFrequencyModal(true)
    }

    useEffect(() => {
        if (!selectedMembership) {
            navigate('/online-booking/memberships')
        }
        setSelectedFrequency(selectedMembership?.membership_payment_options)
    }, [])

    const showMonthly = selectedMembership?.membership_payment_options === MembershipTypes.Monthly
    const showYearly = selectedMembership?.membership_payment_options === MembershipTypes.Yearly
    const showBoth = selectedMembership?.membership_payment_options === MembershipTypes.Both
    const currencyCode = accountInfo.currency_code || 'USD'

    return (
        <div className={`${accountConfig.theme}-theme secondary-bg`}>
            <div className="lg:px-4 py-4 min-h-screen max-h-full">
                <div
                    className="flex gap-[8px] justify-between xl:justify-center xl:gap-4 max-lg:flex-col-reverse lg:flex-row ">
                    <Leftside />
                    <RightContainer title='Memberships'>
                        <div className='border-[1px] border-[#E2E8F0] rounded-2xl p-3'>
                        <div className='flex justify-between items-center flex-wrap'>
                            <div className='text-xl text-[#475569] font-bold inline-block'>{selectedMembership?.tier_name}</div>
                            <div className='flex flex-col items-end mb-[16px]'>
                                {showMonthly && <div className="text-xl text-[#64748B] mb-0"><span className='text-3xl'>{formatCurrencyAmount(currencyCode, selectedMembership?.price_per_month)}</span>/Month</div>}
                                {showYearly && <div className="text-xl text-[#64748B] mb-0"><span className='text-3xl'>{formatCurrencyAmount(currencyCode, selectedMembership?.price_per_year)}</span>/Year</div>}
                                {showBoth && <div className="text-xl text-[#64748B] mb-0"><span className='text-3xl'>{formatCurrencyAmount(currencyCode, selectedMembership?.price_per_month)}</span>/Month <span className='font-normal'>or</span> <span className='text-3xl'>{formatCurrencyAmount(currencyCode, selectedMembership?.price_per_year)}</span>/Year</div>}
                                {selectedMembership?.one_time_setup_fee > 0 && <div className="text-xl text-[#94A3D8] mb-0"><span className='text-2xl'>{formatCurrencyAmount(currencyCode, selectedMembership?.one_time_setup_fee)}</span> Setup Fee</div>}
                            </div>
                        </div>
                        <div>
                            <p className='text-[#64748B] whitespace-pre-wrap'>{selectedMembership?.membership_description_for_booking_portal}</p>
                        </div>
                        {Boolean(selectedMembership?.show_discount_on_portal) && <>
                            {selectedMembership?.discount_percentage > 0 && <div className={`${accountConfig.theme}-theme secondary-button py-4 mb-4`}><div
                                className='font-bold text-2xl text-center'>Get {selectedMembership?.discount_percentage}% off all products</div></div>}
                            {selectedMembership?.tier_products?.length > 0 && <div className='flex flex-col justify-between'>
                                <div className="font-base mb-2 text-left text-xl font-semibold">Free Monthly Products</div>
                                <ul className='list-disc text-left text-[#475569] text-lg'>
                                    {selectedMembership?.tier_products.map(tierProduct => <li
                                        key={tierProduct.product.id}>{tierProduct.product.product_name} - {tierProduct.units} Unit(s)</li>)}
                                </ul>
                            </div>}
                        </>}
                        <div className='flex justify-between flex-wrap gap-2'>
                            <ButtonSecondary text='Back to Memberships' onClick={() => navigate('/online-booking/memberships')} />
                            {selectedFrequency !== MembershipTypes.Both && <ButtonPrimary text='Join Now' onClick={() => {setShowAgreementModal(true); }}/>}
                            {selectedFrequency === MembershipTypes.Both && <ButtonPrimary text='Select frequency' onClick={() => {handleSelectFrequency()}}/>}
                        </div>
                        </div>
                    </RightContainer>
                </div>
            </div>
            {showSelectFrequencyModal && <MembershipModal
        showSelectFrequencyModal={showSelectFrequencyModal}
        setShowSelectFrequencyModal={setShowSelectFrequencyModal}
        selectedFrequency={selectedFrequency}
        setSelectedFrequency={setSelectedFrequency}
        membership={selectedMembership}
        setShowAgreementModal={setShowAgreementModal}
      />}
      {showAgreementModal && <MembershipAgreementModal membership={selectedMembership} selectedFrequency={selectedFrequency} setSelectedFrequency={setSelectedFrequency} handleAddToCart={handleAddToCart} setShowAgreementModal={setShowAgreementModal} agreement={selectedMembership.agreement.agreement_text} />}
        </div>
    )
}

export default MembershipBenefitPage;
