import React from 'react'
import { getToday, formatTimeAmPm } from '../../Utils/globalActions'
import { weekDays } from '../../Utils/constants'
import clockIcon from '../../img/clockIcon.svg'
import DataStore from 'stores/DataStore'
import '../../css/Themes.scss'

const TimeSchedule = (props) => {
    const { selectedClinicinfo } = props;
    const { accountConfig } = DataStore;

    const getDailySchedule = () => {
        const formatedArray = new Array(7).fill('')
        selectedClinicinfo?.clinic_business_hours.forEach(day => {
            formatedArray.splice(day.day-1, 1, day)
        })

       const dailySchedule = weekDays.map((day, index) => {
           return { name: day, from: formatedArray[index] ? formatedArray[index].from_time : '', to: formatedArray[index] ? formatedArray[index].to_time : '' }
        })
        return dailySchedule
    }

    return (
        <div className="text-[#64748B] text-sm xl:text-base tabular-nums overflow-x-scroll">
            <div className="mr-20">
                {getDailySchedule().map((day, index) => {
                    return (
                        <div className='grid grid-cols-[20px_50%_50%] w-full' key={day.name}>
                            <div className='w-4 h-8'>
                                {getToday() === index && <img src={clockIcon} alt="Clock" className='mt-[2px]'/> }
                            </div>
                            <div className={`whitespace-nowrap  ${getToday() === index ? `${accountConfig.theme}-theme primary-color` : ''}`}>{day.name}</div>
                            <div className={`pl-[12px] whitespace-nowrap  ${getToday() === index ? `${accountConfig.theme}-theme primary-color` : ''}`}>{day.from ? `${formatTimeAmPm(day.from)} - ${formatTimeAmPm(day.to)}` : 'Closed'}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TimeSchedule;
