import axios from "axios";
import { uiNotification } from "services/UINotificationService";
import DataStore from "stores/DataStore";

const { pendingRequestsIncrease, pendingRequestsDecrease, setIsGettingData } = DataStore

const CommonApi = axios.create({
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  baseURL: process.env.REACT_APP_API_URL,
})

const handleServerMessages = (messages) => {
  if (messages?.error) {
    for (const errorMessage of messages.error) {
      uiNotification.error(errorMessage)
    }
  }
  if (messages?.warning) {
    for (const warningMessage of messages.warning) {
      uiNotification.warn(warningMessage)
    }
  }
  if (messages?.info) {
    for (const infoMessage of messages.info) {
      uiNotification.success(infoMessage)
    }
  }
}

CommonApi.interceptors.request.use(function (config) {
  pendingRequestsIncrease()
  setIsGettingData(true)
  return config;
}, function (error) {
  return Promise.reject(error);
});

CommonApi.interceptors.response.use(function (response) {
  pendingRequestsDecrease()
  if (DataStore.pendingRequests === 0) setIsGettingData(false)
  handleServerMessages(response.data?.messages)
  return response;
}, function (error) {
  pendingRequestsDecrease()
  if (DataStore.pendingRequests === 0) setIsGettingData(false)
  handleServerMessages(error.response.data?.messages)
  return Promise.reject(error);
});

export default CommonApi;
