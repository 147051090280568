import React, { useState } from 'react'
import ButtonPrimary from '../Common/ButtonPrimary';
import { useNavigate } from 'react-router-dom';
import DataStore from 'stores/DataStore';
import '../../css/Themes.scss'
import { formatDescriptionText } from 'Utils/globalActions';
import StaffStore from 'stores/StaffStore';
import ClinicsStore from 'stores/ClinicsStore';

const StaffBox = (props) => {
    const { staff } = props;
    const {user_image_url, bio_name, bio_title, bio_description, available_services } = staff

    const navigate = useNavigate()
    const { accountConfig } = DataStore;
    const { setPreselectedProvider } = StaffStore
    const { selectedClinic } = ClinicsStore

    const [showMoreServices, setShowMoreServices] = useState(false)

    const renderServices = () => {
        const sortedServices = available_services.sort((a,b) => {
            if(a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1
            }
            if(a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1
            }
            return 0
        })

        if(!showMoreServices) {
            return sortedServices.slice(0, 5)
        }
        return sortedServices
    }

    const handleBooking = () => {
        setPreselectedProvider(staff)
        navigate('/online-booking/book-appointment')
    }

    return (
        <div className='p-8 border border-[#E2E8F0] rounded-lg mb-4'>
            <div className='flex items-center gap-4 mb-2 flex-wrap'>
                {user_image_url ? <img src={user_image_url} alt={`Staff, ${bio_name}`} className='rounded-full h-[100px] object-cover' width="100" /> : <div className='w-[100px] h-[100px] rounded-full bg-[#D0D5DD]'></div>}
                <div>
                    <div className={`${accountConfig.theme}-theme primary-color font-semibold text-xxl text-[24px] break-word`}> {bio_name} </div>
                    <div className="text-[#475569] text-sm break-word"> {bio_title} </div>
                </div>
            </div>
            <div className='text-[#64748B] mb-6 break-word'>
                {formatDescriptionText(bio_description)}
            </div>
            <div>
                <div className='text-lg font-semibold'>Services</div>
                <ul className='list-disc mb-0'>
                    {renderServices().map((service, index) => {
                        return <li key={index} className='text-[#64748B] marker:text-black break-word'>{service.name}</li>
                    })}
                </ul>
                {available_services.length > 5 && <button className={`${accountConfig.theme}-theme primary-color font-semibold cursor-pointer`} onClick={() => setShowMoreServices(!showMoreServices)}>{!showMoreServices ? 'More' : 'Less'} Services</button>}
            </div>
            <div className='flex justify-end pt-[20px] mt-4'>
                {Boolean(selectedClinic?.is_available_online) && <ButtonPrimary text='Book Appointment'  onClick={() => handleBooking()} />}
                
            </div>
        </div>
    )
}

export default StaffBox;
