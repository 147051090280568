import React from "react";
import ReactInputMask from "react-input-mask";
import DataStore from "stores/DataStore";

var FORMAT_MASK_MAP = {
  "m/d/Y": "99/99/9999",
  "d/m/Y": "99/99/9999",
  "Y/m/d": "9999/99/99",
};

export var DATE_FORMATS_MAP = {
  "m/d/Y": "MM/dd/yyyy",
  "d/m/Y": "dd/MM/yyyy",
  "Y/m/d": "yyyy/MM/dd",
};

export var DEFAULT_FORMAT = "m/d/Y";

export function InputDob({ label, value, onChange, isDisabled, isError }) {
  var { accountInfo } = DataStore;
  var format = accountInfo?.date_format || DEFAULT_FORMAT;

  return (
    <div className="flex flex-col gap-[6px]">
      {label && <label>{label}</label>}
      <ReactInputMask
        mask={FORMAT_MASK_MAP[format]}
        maskChar="_"
        placeholder={DATE_FORMATS_MAP[format]?.toUpperCase()}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={isDisabled}
        className={`w-full border-[1px] rounded-lg p-2 ${isError ? "border-[red]" : "border-[#D0D5DD]"
          }`}
      />
    </div>
  );
}
