import React from 'react'

const RightContainer = (props) => {
  const { children, title } = props;
  return (
    <div className="w-full h-fit bg-white container-shadow pt-[32px] p-[16px] rounded-lg lg:w-[800px] xl:p-6 xl:pt-[32px]">
        {title && <h1 className="mb-6 text-[24px]">{title}</h1>}
        {children}
    </div>
  )
}

export default RightContainer;
