import { observer } from "mobx-react-lite";
import React from "react";
import GiftCardStore from "stores/GiftCardStore";

const InputField = (props) => {
    const { name, placeholder, label, giftCardForm, handleGiftCardForm } = props;
    const { giftCardFormError } = GiftCardStore

    return (
        <div className='flex flex-col'>
            <label htmlFor={name} >{label}</label>
            <input type='text' id={name} name={name} value={giftCardForm[name]} className={`border-[1px] rounded-lg p-2 ${Object.keys(giftCardFormError).includes(`gift_card_props.${name}`) ? 'border-[red]' : ''}`} placeholder={placeholder} onChange={handleGiftCardForm} />
        </div>
    )
}

export default observer(InputField);
