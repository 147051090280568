import { computed, makeAutoObservable } from "mobx";

class ClinicsStore {
  isGettingData = true;
  clinics = new Map();
  selectedClinic = null;
  selectedClinicId = null;
  showSelectionModal = false;

  constructor() {
    makeAutoObservable(this, {
      isAddressVisible: computed,
    });
  }

  setClinics = (clinics, silent = false) => {
    this.clinics = new Map(clinics.map((clinic) => [clinic.id, clinic]));
    if (!silent) {
      if (clinics.length > 1) this.showSelectionModal = true;
      else if (clinics.length === 1) this.selectedClinicId = clinics[0].id;
      this.isGettingData = false;
    }
  };

  setSelectedClinic = (clinicId) => {
    this.selectedClinicId = clinicId;
    this.selectedClinic = this.clinics.get(clinicId);
    sessionStorage.removeItem("session-hash");
    this.showSelectionModal = false;
  };

  reset = () => {
    this.clinics = new Map();
    this.selectedClinic = null;
    this.selectedClinicId = null;
    this.showSelectionModal = true;
  };

  get isAddressVisible() {
    return Boolean(this.selectedClinic?.show_address);
  }
}

export default new ClinicsStore();
