import { Toaster } from "react-hot-toast";

export var WarnIcon = () => (
  <span
    style={{
      width: "20px",
      height: "20px",
      minWidth: "20px",
      minHeight: "20px",
      borderRadius: "50%",
      backgroundColor: "white",
      color: "#c68654",
      fontSize: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    !
  </span>
);

export var styles = {
  common: {
    padding: "16px",
    color: "#fff",
    fontWeight: "600",
  },
  success: {
    style: {
      background: "#4ac95b",
    },
    iconTheme: {
      primary: "white",
      secondary: "#4ac95b",
    },
  },
  error: {
    style: {
      background: "#c65454",
    },
    iconTheme: {
      primary: "white",
      secondary: "#c65454",
    },
  },
  warn: {
    style: {
      background: "#c68654",
    },
  },
};

export function UINotificationContainer() {
  return (
    <Toaster
      position="bottom-left"
      toastOptions={{
        style: styles.common,
        success: styles.success,
        error: styles.error,
      }}
    />
  );
}
