export function CloseButton({ onClick }) {
  return (
    <button
      onClick={onClick}
      style={{
        position: "absolute",
        top: "3px",
        right: "5px",
        cursor: "pointer",
        border: "none",
        background: "transparent",
        fontSize: "20px",
        padding: "0px",
        lineHeight: "1",
        color: "#fff",
      }}
    >
      ×
    </button>
  );
}
