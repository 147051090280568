import API from "Utils/API";
import CartStore from "stores/CartStore"
import BookingStore from "stores/BookingStore";
import BookingAPI from "Utils/BookingAPI"
import GiftCardStore from "stores/GiftCardStore";
import ClinicsStore from "stores/ClinicsStore";
import { subdomain } from "Utils/globalActions";
import ServicesStore from "stores/ServicesStore";
import StaffStore from "stores/StaffStore";
import CommonApi from "Utils/CommonApi";
import { uiNotification } from "./UINotificationService";

export const addItemToCart = async (payload, callback) => {
  try {
    const response = await API.post('cart', payload)
    CartStore.setCart(response.data.data)
    typeof callback === 'function' && callback()
  } catch (error) {
    GiftCardStore.setGiftCardFormError(error.response.data.data.errors)
  }
}

export const removeItemFromCart = async (id) => {
  try {
    const response = await API.delete(`cart/${id}`)
    CartStore.setCart(response.data.data)
  } catch (error) {
  }
}

export const generateInvoice = async () => {
  return await API.post('invoice')
}

export const getInvoice = async () => {
  return await API.get('invoice')
}

export const cancelInvoice = async () => {
  return await API.delete('invoice/current')
}

export const chargeClearentCC = async (tokenId) => {
  return await API.post('charge-clearent-cc', {
    tokenId
  })
}

export const getStripePaymentIntent = async () => {
  return await API.get('stripe-intent')
}

export const updateStripePaymentIntent = async (paymentMethodType) => {
  return await API.patch('stripe-intent', {paymentMethodType})
}

export const getBookingStripeSetupIntent = async (formData) => {
  return await BookingAPI.post('setup-intent', formData)
}

export const cancelBookingStripeSetupIntent = async (setupIntentId) => {
  return await BookingAPI.delete(`setup-intent/${setupIntentId}`)
}

export const getServicesAppointment = async (formData) => {
  try {
    const servicesData = await BookingAPI.get('services', formData)
    BookingStore.setServicesData(servicesData.data.services)

    if (StaffStore.preselectedProvider) {
      BookingStore.setSelectedProvider(StaffStore.preselectedProvider)
      const servicesDataProvider = servicesData.data.services.reduce((acc, category) => {
        const availableServicesAssoc = category.service_assoc.filter(service => {
          if (StaffStore.preselectedProviderServicesIds.includes(service.service_id) && service.service_patient_portal.length > 0) {
            return service
          }
          return null
        })

        if (availableServicesAssoc.length > 0) {
          return [...acc, { ...category, service_assoc: availableServicesAssoc }]
        }

        return acc
      }, [])

      BookingStore.setServicesData(servicesDataProvider)

    }


    if (ServicesStore.preselectedService) {
      const selectedCategory = servicesData.data.services.find(category => category.id === ServicesStore.selectedServiceCategory.id)
      ServicesStore.setSelectedServiceCategory(selectedCategory)
      const selectedService = selectedCategory.service_assoc.find(service => service.service_id === ServicesStore.preselectedServiceId)
      BookingStore.setSelectedServices([selectedService.service_patient_portal[0]])
      const noClubbableIds = selectedService?.not_clubbable.map(services => services.not_clubbed_service)
      const noRelatedClubbableSelected = selectedService?.not_clubbable_related.map(services => services.service_id )
      BookingStore.setNoClubbableServicesIds([...noClubbableIds, ...noRelatedClubbableSelected])
      if (!selectedService.service_patient_portal[0].free_consultation && parseFloat(selectedService.service_patient_portal[0].price) > 0.5) {
        ServicesStore.setDeposit(parseFloat(selectedService.service_patient_portal[0].price))
        ServicesStore.setShowDeposit(true)
      }
      ServicesStore.setPreselectedService(null)
    }
  } catch (error) {
  }
}

export const getProviderAppointment = async (formData) => {
  try {
    const providerData = await BookingAPI.get('providers', formData)
    BookingStore.setProviderData(providerData.data)
  } catch (error) {
  }
}

export const getAppointmentDates = async (formData) => {
  try {
    const appointmentDates = await BookingAPI.get('dates', formData)
    BookingStore.setAppointmentDates(appointmentDates.data)
  } catch (error) {
  }
}

export const bookAppointment = async (formData) => { 
  return await BookingAPI.post('book', {
    ...formData,
    lead_id: BookingStore.lead_id,
  })
}

export const getCancellationPolicy = async () => {
  try {
    const cancellationPolicy = await BookingAPI.get('cancellation_policy', { params: { subdomain } })
    BookingStore.setCancellationPolicyText(cancellationPolicy.data.cancellation_policy)
    BookingStore.setAuthText(cancellationPolicy.data.cc_auth_text)
  } catch (error) {
  }
}

export const getOTPCode = async (formData, setShowPhoneModal, resend) => {
  try {
    await BookingAPI.post('verify_number', formData)
    if (resend) uiNotification.success('OTP Sent Successfully!')
    setShowPhoneModal(true)
    BookingStore.setBookingInfoError({})
  } catch (error) {
    BookingStore.setBookingInfoError(error.response.data.errors || BookingStore.bookingInfoError)
  }
}

export const verifyOTP = async (formData, successVerify) => {
  try {
    const verifyData = await BookingAPI.post('verify_otp_on_start', formData)
    if (!verifyData.data.error) {
      BookingStore.setClinicPosEnabled(verifyData.data.canShowPaid)
      successVerify()
    } else {
      uiNotification.error(verifyData.data.message)
    }
  } catch (error) {
  }
}

export const canBeBooked = async (formData) => {
  try {
    await BookingAPI.post('can_be_booked', formData)
  } catch (error) {
  }
}

export const handleClinicSelect = async (clinicId) => {
  try {
    const clinicsResponse = await API.get('clinics');
    ClinicsStore.setClinics(clinicsResponse.data.data.clinics, true);

    const response = await API.put('clinic', {
      clinicId: clinicId
    })
    ClinicsStore.setSelectedClinic(response.data.data.clinicId)
  } catch (error) {
  }
}

export const uploadImage = async (formData) => {
  return await API.post('upload_signature', formData)
}

export const addToCalendar = async (formData) => {
  return await BookingAPI.post('sync_calendars', formData)
}

export const validateCoupon = async (formData) => {
  return await API.post('validate-coupon', formData)
}

export async function registerLeadOb() {
  const { contactInfo } = BookingStore;

  const { data } = await CommonApi.post("leadFromOB", {
    subdomain,
    first_name: contactInfo.firstname,
    last_name: contactInfo.lastname,
    email: contactInfo.email,
    phone_number: contactInfo.phone_number,
    clinic_id: ClinicsStore.selectedClinicId,
  });
  
  if (data.lead_id) {
    BookingStore.setLeadId(data.lead_id)
  }
}
