import React from "react";
import { useNavigate } from "react-router-dom";
import Mapbox from "./GoogleMap";
import ButtonPrimary from "./Common/ButtonPrimary";
import TimeSchedule from "./LeftContainer/TimeSchedule";
import GoogleReviews from "./LeftContainer/GoogleReviews";
import ContactInfo from "./LeftContainer/ContactInfo";
import locationIcon from "../img/locationIcon.svg";
import { observer } from "mobx-react-lite";
import DataStore from "stores/DataStore";
import ClinicsStore from "stores/ClinicsStore";
import { formatDescriptionText, shortenClinicName } from "Utils/globalActions";
import { formatPhoneNumber } from "react-phone-number-input";

const Mailto = ({ email, subject, body, ...props }) => {
  return (
    <a
      href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
      className="no-underline"
      aria-label={`Send an email to ${email}`}
    >
      {props.children}
    </a>
  );
};

const Leftside = () => {
  const { selectedClinic, isAddressVisible } = ClinicsStore;
  const { accountConfig } = DataStore;

  const selectedClinicinfo = selectedClinic;
  const accountDetails = selectedClinic;

  const completeAddress = [
    selectedClinicinfo?.address,
    selectedClinicinfo?.suite_number,
    selectedClinicinfo?.clinic_city,
    selectedClinicinfo?.clinic_state,
    selectedClinicinfo?.clinic_zipcode,
  ];

  const additionalLocationInformation = [
    selectedClinicinfo?.additional_location_booking,
  ];

  const navigate = useNavigate();

  return (
    <div className="w-full h-fit bg-white container-shadow pt-[32px] p-[8px] rounded-lg lg:p-4 lg:pt-[32px] lg:w-[400px] xl:p-6 xl:pt-[32px]">
      <div className="flex flex-col text-center mb-4">
        <div className="text-lg mb-3 xl:text-xl font-bold">
          {shortenClinicName(accountDetails?.clinic_name)}
        </div>
        {Boolean(selectedClinic?.is_available_online) && (
          <ButtonPrimary
            text="Book an Appointment"
            onClick={() => navigate("/online-booking/book-appointment")}
          />
        )}
      </div>
      <div className="text-[#64748B]">
        <div className="text-sm xl:text-base break-words mb-4">
          {formatDescriptionText(accountConfig?.practice_info_description)}
        </div>
      </div>
      {isAddressVisible && (
        <>
          <div className="text-base xl:text-lg text-[#334155] mb-4 font-semibold">
            Location and Hours
          </div>
          <div className="rounded-lg">
            <Mapbox selectedClinicinfo={selectedClinicinfo} />
          </div>
        </>
      )}
      <div className="border-b border-[#F1F5F9] pb-8 mb-8">
        {isAddressVisible && (
          <>
            <div className="text-sm xl:text-base text-[#64748B] flex mt-2">
              <div className="min-w-[16px] mr-2">
                <img src={locationIcon} alt="location" className="mt-1" />
              </div>
              <span>{completeAddress.filter(Boolean).join(", ")}</span>
            </div>
            <span className="text-sm xl:text-base text-[#64748B] flex mb-2 d-block additional-location-information">
              {additionalLocationInformation.filter(Boolean).join(", ")}
            </span>
          </>
        )}
        <TimeSchedule selectedClinicinfo={selectedClinicinfo} />
      </div>
      {Boolean(accountDetails?.is_google_active) && <GoogleReviews />}
      <div className="flex flex-col justify-center gap-3">
        {accountConfig?.website && (
          <ContactInfo icon={"globeIcon"}>
            <a
              href={accountConfig?.website || ""}
              target="_blank"
              rel="noreferrer"
              className={`${accountConfig.theme}-theme primary-color no-underline`}
            >
              {accountConfig?.website}
            </a>
          </ContactInfo>
        )}
        {accountConfig?.email && (
          <ContactInfo icon={"mailIcon"}>
            <Mailto email={accountConfig?.email}>
              <span className={`${accountConfig.theme}-theme primary-color`}>
                {accountConfig?.email}
              </span>
            </Mailto>
          </ContactInfo>
        )}
        {accountConfig?.phone_number && (
          <ContactInfo icon={"phoneIcon"}>
            <a
              href={`tel:${accountConfig?.phone_number}`}
              className={`${accountConfig.theme}-theme primary-color no-underline`}
              aria-label={`Call ${accountConfig?.phone_number}`}
            >
              {formatPhoneNumber(accountConfig?.phone_number)}
            </a>
          </ContactInfo>
        )}
      </div>
    </div>
  );
};

export default observer(Leftside);
