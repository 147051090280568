import { toast } from "react-hot-toast";
import { CloseButton } from "./CloseButton";
import { styles, WarnIcon } from "./Container";

var getContent = (message) => (t) =>
  (
    <span>
      {message}
      <CloseButton onClick={() => toast.dismiss(t.id)} />
    </span>
  );

export class UINotificationService {
  constructor(repository) {
    this.repo = repository;
  }

  error(message) {
    if (message) {
      this.repo.error(getContent(message));
    }
  }

  success(message) {
    if (message) {
      this.repo.success(getContent(message));
    }
  }

  warn(message) {
    if (message) {
      this.repo(getContent(message), {
        duration: 4000,
        removeDelay: 4000,
        style: styles.warn.style,
        icon: <WarnIcon />,
      });
    }
  }

  clear() {
    this.repo.dismiss();
  }
}

const createUINotificationService = () => {
  return new UINotificationService(toast);
};

export const uiNotification = createUINotificationService();
