import React from 'react'
import DataStore from 'stores/DataStore';
import { MembershipTypes } from "Utils/constants"
import ButtonSecondary from 'component/Common/ButtonSecondary';
import ButtonPrimary from 'component/Common/ButtonPrimary';

const MembershipModal = (props) => {
  const {
    setShowSelectFrequencyModal,
    membership,
    selectedFrequency,
    setSelectedFrequency,
    setShowAgreementModal
  } = props;
  const { accountConfig } = DataStore;

  return (
    <div className='fixed top-0 left-0 bg-black/30 w-full h-screen flex justify-center items-center z-[999]'>
      <div className='max-w-[280px] bg-[white] rounded-2xl p-6 flex flex-col items-center mb-[200px]'>
        <div className='mb-6 font-semibold text-xl break-word'>Please select payment frequency option</div>
        <div className='flex w-full justify-center gap-6 mb-4'>
          <button className={`w-[100px] h-[100px] flex flex-col justify-center items-center border-[1px] select-none cursor-pointer ${selectedFrequency === MembershipTypes.Monthly ? `${accountConfig.theme}-theme card-active` : 'border-[#CBD5E1] bg-[#F1F5F9]'}  rounded-lg`}
            onClick={() => {
              setSelectedFrequency(MembershipTypes.Monthly)
            }}
          >
            <span className={`${accountConfig.theme}-theme primary-color font-bold`}>${membership.price_per_month}</span>
            <span>Monthly</span>
          </button>
          <button className={`w-[100px] h-[100px] flex flex-col justify-center items-center border-[1px] select-none cursor-pointer ${selectedFrequency === MembershipTypes.Yearly ? `${accountConfig.theme}-theme card-active` : 'border-[#CBD5E1] bg-[#F1F5F9]'}  rounded-lg`}
            onClick={() => {
              setSelectedFrequency(MembershipTypes.Yearly)
            }}
          >
            <span className={`${accountConfig.theme}-theme primary-color font-bold`}>${membership.price_per_year}</span>
            <span>Yearly</span>
          </button>
        </div>

        {selectedFrequency !== MembershipTypes.Both &&
          <div className='w-full flex flex-col mb-2'>
            <ButtonPrimary text='Join Now' onClick={() => {
              setShowSelectFrequencyModal(false)
              setShowAgreementModal(true)
            }} />
          </div>}

        <div className='w-full flex flex-col'>
          <ButtonSecondary text='Back' onClick={() => {
            setShowSelectFrequencyModal(false)
            setSelectedFrequency(MembershipTypes.Both)
            }} />

        </div>
      </div>
    </div>
  )
}

export default MembershipModal;
