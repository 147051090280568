import { makeAutoObservable } from "mobx"

class BookingStore {
    step = 1
    bookWithFirst = false
    contactInfo = { firstname: '', lastname: '', dob: null, email: '', phone_number: '', country: '', appointment_notes: '' }
    numberVerified = ''
    bookingInfoError = {}
    appointmentType = ''
    clinicData = {}
    servicesData = []
    selectedServices = []
    providerData = []
    selectedProvider = null
    appointmentDates = []
    selectedHour = ''
    selectedDateAndHour = ''
    selectedApiDate = ''
    bookAppointment = null
    cancellationPolicyText = null
    authText = null
    otpCode = null
    clinicPosEnabled = null
    noClubbableServicesIds = []
    lead_id = null
    consentSms = false
    consentEmail = false
    doNotConsent = false

    constructor() {
        makeAutoObservable(this)
    }

    get bookingFullName() {
        return `${this.contactInfo.firstname} ${this.contactInfo.lastname}`
    } 

    setStep = (nextStep) => {
        this.step = nextStep;
    }

    setBookWithFirst = (value) => {
        this.bookWithFirst = value
        if(!value) {
            this.selectedProvider = null
        }
    }

    setContactInfo = (value) => {
        this.contactInfo = value
    }

    setAppointmentType = (value) => {
        this.appointmentType = value
    }
    
    setClinicData = (value) => {
        this.clinicData = value
    }
    
    setServicesData = (value) => {
        this.servicesData = value
    }

    setSelectedServices = (value) => {
        this.selectedServices = value
    }

    setProviderData = (value) => {
        this.providerData = value
    }

    setSelectedProvider = (value) => {
        this.selectedProvider = value
    }

    setAppointmentDates = (value) => {
        this.appointmentDates = value
    }

    setSelectedHour = (value) => {
        this.selectedHour = value
    }

    setSelectedDateAndHour = (value) => {
        this.selectedDateAndHour = value
    }
    
    setBookAppointment = (value) => {
        this.bookAppointment = value
    }

    setSelectedApiDate = (value) => {
        this.selectedApiDate = value
    }

    reset = () => {
        this.step = 1
        this.contactInfo = { firstname: '', lastname: '', dob: null, email: '', phone_number: '' }
        this.appointmentType = ''
        this.selectedServices = []
        this.selectedProvider = null
        this.servicesData = []
        this.bookingInfoError = {}
        this.selectedHour = ''
        this.selectedDateAndHour = ''
        this.selectedApiDate = ''
        this.numberVerified = false
        this.noClubbableServicesIds = []
        this.bookWithFirst = false
        this.consentSms = false
        this.consentEmail = false
        this.doNotConsent = false
    }

    setCancellationPolicyText = (value) => {
        this.cancellationPolicyText = value
    }

    setAuthText = (value) => {
        this.authText = value
    }

    setOTPCode = (value) => {
        this.otpCode = value;
    }

    setNumberVerified = (value) => {
        this.numberVerified = value
    }

    setBookingInfoError = value => {
        this.bookingInfoError = value
    }

    setClinicPosEnabled = value => {
        this.clinicPosEnabled = value
    }
    setNoClubbableServicesIds = (value) => {
        this.noClubbableServicesIds = value
    }

    get isBookingDataTouched() {
        const {country, ...fillableContactInfo} = this.contactInfo;
        return Object.values(fillableContactInfo).some(Boolean) || this.step !== 1;
    }
    
    setSmsConsent = (value) => {
        this.consentSms = value
        this.doNotConsent = false
    }

    setEmailConsent = (value) => {
        this.consentEmail = value
        this.doNotConsent = false
    }

    setDoNotConsent = (value) => {
        this.doNotConsent = value
        this.consentEmail = false
        this.consentSms = false
    }

    get consent() {
      if (this.consentSms || this.consentEmail) {
        return 1;
      }

      return 0;
    }

    get isConsentActive() {
      return this.consentSms || this.consentEmail || this.doNotConsent;
    }

    setLeadId = (nextLeadId) => {
        this.lead_id = nextLeadId;
    }
}

export default new BookingStore();
