import UserIcon from 'img/UserIcon';
import React from 'react'
import DataStore from 'stores/DataStore';
import '../../css/Themes.scss'

const ButtonSecondary = (props) => {
    const { text, onClick, showUserIcon } = props;
    const { accountConfig } = DataStore;
    return (
        <button className={`${accountConfig.theme}-theme secondary-button py-2 px-6 rounded-[30px] font-semibold break-word cursor-pointer text-sm select-none ${showUserIcon ? 'flex gap-2' : ''}`} onClick={onClick}>
            {showUserIcon && <UserIcon />}
            {text}
        </button>
    )
}

export default ButtonSecondary;
