import React from 'react'
import { IoStar } from 'react-icons/io5';
import Googlepng from '../../img/googleborder.png'

const GoogleReviews = () => {
    return (
        <div className="mb-8">
            <div className="flex items-center mb-2">
                <div className="mr-2">
                    <img src={Googlepng} alt="Google logo" width="62" height="51" />
                </div>
                <div>
                    <div className="text-[#0353a4] text-base font-bold">Google Reviews</div>
                    <div className="flex text-[#fbbc04] gap-1"><IoStar /><IoStar /><IoStar /><IoStar /><IoStar /></div>
                </div>
            </div>
            <div>4.9 Star | 152 reviews</div>
        </div>
    )
}

export default GoogleReviews;
