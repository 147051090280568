import useOnClickOutside from "hooks/useOnClickOutside";
import SearchIcon from "img/SearchIcon";
import React, { useRef, useState } from "react";

const Select = ({
  options,
  value,
  setValue,
  isError,
  search,
  leftAdornment,
  placeholder,
  ariaLabel,
  fullWidth,
}) => {
  var search_ = search ?? true;

  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const selectInput = useRef();
  const buttonRef = useRef();

  const searchedOptions = options?.filter((option) => {
    if (option.label.toLowerCase().includes(searchValue.toLowerCase())) {
      return option;
    }
    return null;
  });

  const handleCloseDropdown = () => {
    setShowOptions(false);
    setSearchValue("");
  };

  useOnClickOutside(
    selectInput,
    handleCloseDropdown,
    (e) =>
      selectInput?.current?.contains(e.target) ||
      buttonRef?.current?.contains(e.target),
  );

  return (
    <div className={`relative ${fullWidth ? "w-full" : ""}`}>
      <button
        aria-label={ariaLabel}
        ref={buttonRef}
        className={`text-left w-full border-[1px] border-solid flex items-center gap-2 ${
          isError ? "border-[red]" : "border-[#dee2e6]"
        } py-2 pl-4 pr-16 rounded-lg relative cursor-pointer min-w-[240px] max-sm:text-sm`}
        onClick={() => setShowOptions((x) => !x)}
      >
        {leftAdornment}
        {value?.label || placeholder || "Select..."}
        <div className="absolute top-[50%] translate-y-[-50%] right-[13px]">
          <svg
            width="10"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L9 9L17 1"
              stroke="#525252"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {showOptions && (
        <div
          className="absolute top-[calc(100%+6px)] left-0 p-0 bg-white border rounded max-h-[200px] w-full"
          ref={selectInput}
        >
          {search_ && (
            <div className="flex items-center h-[50px] px-2 py-2 border-b hover:bg-gray-100">
              <div className="flex items-center px-2 border rounded h-[30px] w-full">
                <SearchIcon width={16} height={16} />
                <input
                  className="px-2 outline-none w-full"
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </div>
          )}
          <div className="p-0 max-h-[200px] bg-white overflow-auto overscroll-y-contain border rounded flex flex-col">
            {searchedOptions?.map((option) => {
              return (
                <button
                  key={option.value}
                  aria-label={option.ariaLabel}
                  className="text-left px-[16px] py-2 border-b hover:bg-gray-100 cursor-pointer duration-75 whitespace-nowrap max-sm:text-sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue(option);
                    handleCloseDropdown();
                  }}
                >
                  {option.label}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Select;
