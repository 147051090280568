import React, { useEffect } from "react";
import Leftside from "../Leftside";
import { observer } from "mobx-react-lite";
import RightContainer from "../RightContainer";
import ServiceBox from "../Services/ServiceBox";
import useGetServices from "hooks/useGetServices";
import ServicesStore from "stores/ServicesStore";
import DataStore from "stores/DataStore";
import "../../css/Themes.scss";
import { useSearchParams } from "react-router-dom";
import { GAEvent, GAEventTypes } from "Utils/GoogleAnalytics";
import Select from "component/Common/Select";

const HomePage = () => {
  const {
    servicesData,
    servicesNames,
    selectedServiceCategory,
    setSelectedServiceCategory,
    loadedServices,
  } = ServicesStore;
  const { accountConfig } = DataStore;
  const [params] = useSearchParams();

  const showServicesContainer =
    params.get("services") === "true" || window.innerWidth > 1024;

  useEffect(() => {
    setSelectedServiceCategory(servicesNames[0]);
  }, [servicesNames]);

  useEffect(() => {
    if (showServicesContainer) {
      GAEvent(GAEventTypes.SERVICES_PAGE_VIEW);
    }
  }, [showServicesContainer]);

  useGetServices();

  const getSelectedServices = (selectedServiceCategory) => {
    const servicesCategory = Object.values(servicesData).find(
      (service) => service.name === selectedServiceCategory?.name,
    );
    return servicesCategory;
  };

  getSelectedServices(selectedServiceCategory);

  return (
    <>
      <div className={`${accountConfig.theme}-theme secondary-bg`}>
        <div className="lg:px-4 py-4 min-h-screen max-h-full">
          <div className="flex gap-[8px] justify-between xl:justify-center xl:gap-4 max-lg:flex-col-reverse lg:flex-row ">
            <Leftside />
            {showServicesContainer && (
              <RightContainer>
                {loadedServices ? (
                  <>
                    {servicesNames.length > 0 ? (
                      <>
                        <div
                          className="flex mb-6 gap-4 flex-col sm:flex-row sm:items-center flex-wrap"
                        >
                          <h1 className="mb-0 text-[24px]">
                            Choose a Service Category
                          </h1>
                          <Select
                            ariaLabel="Select service category"
                            search={false}
                            options={servicesNames.map((s) => ({
                              label: s.name,
                              value: s.id,
                              ariaLabel: `Select ${s.name} category`,
                            }))}
                            setValue={(option) => {
                              setSelectedServiceCategory({
                                id: option.value,
                                name: option.label,
                              });
                            }}
                            value={
                              selectedServiceCategory
                                ? {
                                    label: selectedServiceCategory.name,
                                    value: selectedServiceCategory.id,
                                  }
                                : null
                            }
                          />
                        </div>
                        {getSelectedServices(
                          selectedServiceCategory,
                        )?.available_services.map((service) => (
                          <ServiceBox
                            key={service.id}
                            service={service}
                            selectedServiceCategory={selectedServiceCategory}
                          />
                        ))}
                      </>
                    ) : (
                      "No Services Available For Online Booking"
                    )}
                  </>
                ) : null}
              </RightContainer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(HomePage);
