import React from 'react'
import DataStore from 'stores/DataStore';
import { observer } from 'mobx-react-lite';
import { formatCurrencyAmount } from 'Utils/globalActions';

const GiftCardBox = (props) => {
  const { giftCard } = props;
  const { accountInfo } = DataStore;

  const currencyCode = accountInfo.currency_code || 'USD'

  return (
    <div>
      <div className='promotions_card flex gap-6 rounded-2xl min-h-[185px] p-[16px] xl:p-6 items-center mb-[16px]'>
        <div className='flex flex-col w-1/2  justify-center text-[#64748b]'>
          <div className="text-2xl font-bold break-word">{formatCurrencyAmount(currencyCode, giftCard.amount)}</div>
          <div className="text-lg break-word">{giftCard.name}</div>
        </div>
        <div className='w-1/2'>
          <img src={accountInfo.logo_url} alt={`${giftCard.name} gift card`} className='w-[200px]'/>
        </div>
      </div>
    </div>
  )
}

export default observer(GiftCardBox);
