import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LocationDropdown from "./LocationDropdown"
import Nav from 'react-bootstrap/Nav';
import ButtonPrimary from "./Common/ButtonPrimary";
import ButtonSecondary from "./Common/ButtonSecondary";
import { observer } from "mobx-react-lite";
import DataStore from "stores/DataStore";
import CartStore from "stores/CartStore";
import ClinicsStore from "stores/ClinicsStore";
import '../css/Themes.scss'
import ReactGA from "react-ga4";
import useManifestUpdater from "hooks/useManifestUpdater";
import BookingStore from "stores/BookingStore";

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { clinics, selectedClinic } = ClinicsStore;
    const { totalItems } = CartStore;
    const { accountInfo, accountConfig, fullName, isLoggedIn, isCartAvailable } = DataStore;
    const [navbarShown, setNavbarShown] = useState(false);

    useManifestUpdater({
        name: accountInfo.name,
        logo_url: accountInfo.logo_url,
      });

    
      useEffect(() => {
        if (accountConfig?.analytics_tracking_id && ReactGA.isInitialized) {
          ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
          });
        }
      }, [accountConfig, location.pathname]);
    

    const navBar = [
        {
            label: 'Services',
            to: '/online-booking',
            queryParams: 'services=true',
            isActive: accountConfig?.is_services_active !== false
        },
        {
            label: 'Staff',
            to: '/online-booking/staff',
            isActive: accountConfig?.is_staff_active
        },
        {
            label: 'Memberships',
            to: '/online-booking/memberships',
            isActive: accountConfig?.is_membership_active
        },
        {
            label: 'Promotions',
            to: '/online-booking/promotions',
            isActive: accountConfig?.is_promotion_active
        },
        {
            label: 'eGift Cards',
            to: '/online-booking/giftcards',
            isActive: accountConfig?.is_gift_card_active
        },
    ]

    const onBookNow = () => {
        const confirmMsg = 'All data will be reset and booking process will start again. Do you wish to proceed?';
        if (
            location.pathname === '/online-booking/book-appointment' &&
            BookingStore.isBookingDataTouched &&
            window.confirm(confirmMsg)
        ) {
            BookingStore.reset();
        } else {
            navigate('/online-booking/book-appointment');
        }
    }

    const isText2PayPage = () => {
        const { pathname } = location;
        const text2PayPages = [
          "/online-booking/payment-transaction-success",
          "/online-booking/payment-transaction-failed",
        ];
    
        return text2PayPages.includes(pathname);
      };

    return (
        <header className="px-[8px] xl:px-[32px] py-2 z-10 flex items-center justify-between sticky top-0 bg-white gap-2">
            <div className="flex items-center gap-[4px] sm:gap-[16px] lg:pl-4">
                <Link aria-label="Home page" to="/online-booking" className="max-[450px]:hidden">
                    <img src={accountInfo.logo_url} className="min-w-[45px] max-w-[45px]" alt="Site logo" />
                </Link>
                <Nav>
                    {clinics.size > 0 && !isText2PayPage() && <LocationDropdown  />}
                </Nav>
            </div>
            <div className="flex">
                <div className=" hidden lg:flex items-center justify-center xl:mr-4">
                    {navBar.map(page => {
                        const isActive = page.to === location.pathname
                        if (page.isActive) {
                            return <Link to={page.queryParams ? `${page.to}?${page.queryParams}` : page.to} className={`whitespace-nowrap text-[#475569] px-2 2xl:px-2 py-2 text-sm xl:text-base font-semibold no-underline ${isActive ? `${accountConfig.theme}-theme page-active ` : ''} `} onClick={() => setNavbarShown(false)} key={page.label}>{page.label}</Link>
                        }
                        return null;
                    })}
                </div>
                <div className="flex items-center gap-3 lg:gap-4">
                    {isCartAvailable && <Link aria-label="Cart" to="/online-booking/cart" >
                        <div className="text-base lg:text-[20px] relative mr-1 text-[#475569]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                            <span className={`${accountConfig.theme}-theme primary-button flex items-center justify-center absolute top-[-5%] left-[70%]  text-white rounded-full w-4 h-4 text-xs`}>{totalItems}</span> {/*todo*/}
                        </div>
                    </Link>}
                    <div className="flex items-center gap-2">
                        <div className="item-center gap-2 hidden lg:flex">
                            {<ButtonSecondary text={isLoggedIn ? fullName : 'Login'} showUserIcon={isLoggedIn} onClick={isLoggedIn ? () => navigate('/online-booking/redirect/dashboard') : () => navigate('/online-booking/redirect/login') } />}
                            {Boolean(selectedClinic?.is_available_online) && <ButtonPrimary text='Book Now' onClick={onBookNow} />}
                        </div>

                        <button aria-label="Open Menu" className="w-5 h-4 flex flex-column justify-between lg:hidden cursor-pointer" onClick={() => setNavbarShown(!navbarShown)}>
                            <span className="inline-block w-full h-0.5 bg-[grey] ml-auto"></span>
                            <span className="inline-block w-full h-0.5 bg-[grey] ml-auto"></span>
                            <span className="inline-block w-3/4 h-0.5 bg-[grey] ml-auto "></span>
                        </button>
                    </div>

                </div></div>
            <div className="fixed inset-0 pointer-events-none">
                <div className={`bg-black z-10 bg-opacity-25 fixed inset-0 pointer-events-auto ${navbarShown ? '' : 'hidden'}`} role="none" onClick={() => setNavbarShown(false)} onKeyDown={() => setNavbarShown(false)}></div>
                <div className={`bg-white flex flex-column fixed top-0 bottom-0 right-0 w-[320px] px-4 py-6 z-50 duration-150 pointer-events-auto  ${navbarShown ? '' : 'translate-x-[320px]'}`}>
                    <button className="absolute top-4 right-4 cursor-pointer w-[30px] h-[30px]" onClick={() => setNavbarShown(false)}>X</button>
                    <div className="flex flex-column gap-3 pt-2 ">
                        {navBar.map(page => {
                            const isActive = page.to === location.pathname
                            if (page.isActive) {
                                return <Link to={page.queryParams ? `${page.to}?${page.queryParams}` : page.to} className={`whitespace-nowrap text-[#475569] hover:text-[#0A58CA] px-2 2xl:px-2 py-2 text-sm xl:text-base font-semibold no-underline ${isActive ? `${accountConfig.theme}-theme page-active` : ''}`} onClick={() => setNavbarShown(false)} key={page.label}>{page.label}</Link>
                            }
                            return null;
                        })}
                    </div>
                    <div className="item-center gap-2 flex flex-column mt-auto ">
                        {<ButtonSecondary text={isLoggedIn ? fullName : 'Login'} onClick={isLoggedIn ? () => {navigate('/online-booking/redirect/dashboard'); setNavbarShown(false)} : () => {navigate('/online-booking/redirect/login'); setNavbarShown(false)} } />}
                        {Boolean(selectedClinic?.is_available_online) && <ButtonPrimary text='Book Now' onClick={() => {
                            onBookNow();
                            setNavbarShown(false);
                        }} />}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default observer(Header);
