import React from "react";
import ClinicsStore from "stores/ClinicsStore";
import { observer } from "mobx-react-lite";
import DataStore from "stores/DataStore";
import { handleClinicSelect } from "services/HttpServices";
import BookingStore from "stores/BookingStore";
import { shortenClinicName } from "Utils/globalActions";
import Select from "./Common/Select";
import CommonModal from "./Common/CommonModal";

const MyVerticallyCenteredModal = observer(() => {
  const { clinics, selectedClinic } = ClinicsStore;
  const { accountInfo } = DataStore;

  const handleSelect = async (option) => {
    await handleClinicSelect(option.value);
  };

  return (
    <CommonModal>
      <div className="text-center pt-3 pb-3">
        <img src={accountInfo.logo_url} alt="Logo" width="150" />
      </div>
      <div className="pt-3 pb-3 font-semibold text-lg">
        Select a location for one of our clinics!
      </div>
      <Select
        ariaLabel="Select clinic"
        search={false}
        placeholder="Select clinic"
        options={Array.from(clinics.values()).map((c) => ({
          label: shortenClinicName(c.clinic_name),
          value: c.id,
          ariaLabel: `Select ${c.clinic_name}`,
        }))}
        value={
          selectedClinic
            ? {
                label: shortenClinicName(selectedClinic.clinic_name),
                value: selectedClinic.id,
              }
            : null
        }
        setValue={handleSelect}
        leftAdornment={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            style={{ minWidth: "16px" }}
            fill="currentColor"
            className="bi bi-geo-alt"
            viewBox="0 0 16 16"
          >
            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg>
        }
      />
    </CommonModal>
  );
});

const LocationDropdown = () => {
  const { clinics, selectedClinic, showSelectionModal } = ClinicsStore;

  const handleSelect = async (option) => {
    await handleClinicSelect(option.value);
    if (BookingStore.step !== 1) {
      BookingStore.reset();
    }
  };

  return (
    <>
      <Select
        ariaLabel="Select clinic"
        search={false}
        placeholder="Select clinic"
        options={Array.from(clinics.values()).map((c) => ({
          label: shortenClinicName(c.clinic_name),
          value: c.id,
          ariaLabel: `Select ${c.clinic_name}`,
        }))}
        value={
          selectedClinic
            ? {
                label: shortenClinicName(selectedClinic.clinic_name),
                value: selectedClinic.id,
              }
            : null
        }
        setValue={handleSelect}
        leftAdornment={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            style={{ minWidth: "16px" }}
            height="16"
            fill="currentColor"
            className="bi bi-geo-alt"
            viewBox="0 0 16 16"
          >
            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg>
        }
      />
      {showSelectionModal && <MyVerticallyCenteredModal />}
    </>
  );
};

export default observer(LocationDropdown);
