import { composeUrl } from "Utils/globalActions";
import { observer } from "mobx-react-lite";
import DataStore from "stores/DataStore";

export const Footer = observer(() => {
  const config = DataStore.accountConfig;

  if (!config.privacy) {
    return null;
  }

  return (
    <footer
      className={`px-6 pb-4 secondary-bg ${config.theme}-theme pt-[200px] text-right`}
    >
      <hr className="border-t-[1px]" />
      {config.privacy && (
        <a
          href={composeUrl(config.privacy)}
          target="_blank"
          rel="noreferrer noopener"
          className="font-light text-gray-600 text-sm"
          aria-label="Privacy Policy - open in a new tab"
        >
          Privacy Policy
        </a>
      )}
    </footer>
  );
});
