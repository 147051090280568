import React, { useEffect, useRef, useState } from "react";
import ReadMoreModal from "./ReadMoreModal";
import DepositModal from "./DepositModal";
import ButtonPrimary from "component/Common/ButtonPrimary";
import ButtonSecondary from "component/Common/ButtonSecondary";
import ClinicsStore from "stores/ClinicsStore";
import BookingStore from "stores/BookingStore";
import {
  getProviderAppointment,
  getServicesAppointment,
} from "services/HttpServices";
import { observer } from "mobx-react-lite";
import DataStore from "stores/DataStore";
import { subdomain } from "Utils/globalActions";
import ServicesStore from "stores/ServicesStore";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { GAEvent, GAEventTypes } from "Utils/GoogleAnalytics";
import { useScrollToTop } from "hooks/useSrollToTop";
import { CallToScheduleServiceModal } from "component/modals/CallToScheduleServiceModal";

const StepTwo = () => {
  useScrollToTop();
  const { selectedClinic } = ClinicsStore;
  const { accountConfig } = DataStore;
  const {
    selectedServiceCategory,
    setSelectedServiceCategory,
    deposit,
    setDeposit,
    showDeposit,
    setShowDeposit,
  } = ServicesStore;
  const {
    setStep,
    servicesData,
    clinicData,
    appointmentType,
    setAppointmentType,
    selectedServices,
    setSelectedServices,
    noClubbableServicesIds,
    setNoClubbableServicesIds,
  } = BookingStore;
  const [readMore, setReadMore] = useState({ title: "", description: "" });
  const [showReadMoreModal, setShowReadMoreModal] = useState(false);
  const [isCallClinicModalOpen, setIsCallClinicModalOpen] = useState(false);

  const selectedServicesIds = selectedServices.map((service) => service.id);

  const sortedServicesNames = servicesData.slice().sort((a,b) => a.order_by - b.order_by);

  const renderAvailableCategories = () => {
    return sortedServicesNames;
  };

  const handleAppointmentType = (type) => {
    setAppointmentType(type);
    setSelectedServices([]);
    setSelectedServiceCategory(null);
    getServicesAppointment({
      params: {
        subdomain,
        clinic_id: selectedClinic.id,
        appointment_type: type,
        vertical_id: clinicData.vertical_id,
      },
    });
  };

  const selectService = (service) => {
    const serviceSelected = selectedServiceCategory?.service_assoc.find(
      (serviceAssoc) => serviceAssoc.service_id === service.id,
    );
    const noClubbableSelected = serviceSelected?.not_clubbable.map(
      (services) => services.not_clubbed_service,
    );
    const noRelatedClubbableSelected =
      serviceSelected?.not_clubbable_related.map(
        (services) => services.service_id,
      );
    const noClubbableIds = [
      ...noClubbableSelected,
      ...noRelatedClubbableSelected,
    ];

    if (noClubbableServicesIds.includes(service.id)) return;

    if (selectedServicesIds.includes(service.id)) {
      const newSelectedService = selectedServices.filter(
        (selectedService) => selectedService.id !== service.id,
      );
      const newNoClubbableServicesIds = noClubbableServicesIds.filter(
        (serviceId) => !noClubbableIds.includes(serviceId),
      );
      setSelectedServices(newSelectedService);
      setNoClubbableServicesIds(newNoClubbableServicesIds);
    } else {
      setSelectedServices([...selectedServices, service]);
      setNoClubbableServicesIds([...noClubbableServicesIds, ...noClubbableIds]);
      if (!service.free_consultation && parseFloat(service.price) > 0.5) {
        setDeposit(parseFloat(service.price));
        setShowDeposit(true);
      }
    }
  };

  const selectReadMore = (event, service) => {
    event.stopPropagation();
    setReadMore({ title: service.name, description: service.description });
    setShowReadMoreModal(true);
  };

  const handleVirtualButton = () => {
    const selectedClinic = clinicData?.all_clinics?.find(
      (clinic) => clinic.id === ClinicsStore.selectedClinic?.id,
    );
    return selectedClinic?.has_virtual_services;
  };

  const getServicesByCategory = () => {
    const services = servicesData?.find(
      (category) => category.id === selectedServiceCategory?.id,
    );

    const servicesPatientPortal = services?.service_assoc?.map(
      (servicePP) => servicePP.service_patient_portal,
    );

    return servicesPatientPortal?.flat() || [];
  };

  const handleNext = () => {
    if (selectedServices.some((x) => x.is_display_only)) {
      return setIsCallClinicModalOpen(true);
    }
    GAEvent(GAEventTypes.SERVICES_SELECTED);
    setStep(3);
    getProviderAppointment({
      params: {
        subdomain,
        clinic_id: selectedClinic.id,
        services: selectedServices.map((service) => service.id),
        appointment_type: appointmentType,
      },
    });
  };

  const handlePrevious = () => {
    setSelectedServices([]);
    setStep(1);
  };

  const [showOptions, setShowOptions] = useState(false);
  const servicesList = useRef();

  useOnClickOutside(servicesList, () => setShowOptions(false));

  useEffect(() => {
    if (appointmentType) {
      getServicesAppointment({
        params: {
          subdomain,
          clinic_id: selectedClinic.id,
          appointment_type: appointmentType,
          vertical_id: clinicData.vertical_id,
        },
      });
    }
  }, []);

  return (
    <div>
      <div className="mb-6">
        <div className="mb-6 text-[16px] font-semibold">
          What type of appointment do you want to book?
        </div>
        <div className="flex h-[44px]">
          <button
            className={`min-w-[120px] whitespace-nowrap flex items-center justify-center cursor-pointer p-3 border border-[#E2E8F0] font-semibold rounded-l-[40px] disabled:cursor-not-allowed ${appointmentType === "in_person"
                ? `${accountConfig.theme}-theme primary-button text-white font-[900]`
                : ""
              }`}
            onClick={() => handleAppointmentType("in_person")}
          >
            In Person
          </button>
          <button
            className={`min-w-[120px] flex items-center justify-center cursor-pointer p-3 border border-[#E2E8F0] font-semibold rounded-r-[40px] disabled:cursor-not-allowed ${appointmentType === "virtual"
                ? `${accountConfig.theme}-theme primary-button text-white`
                : ""
              }`}
            onClick={() => handleAppointmentType("virtual")}
            disabled={!handleVirtualButton()}
          >
            Virtual
          </button>
        </div>
      </div>
      <div className="mb-6">
        {appointmentType && (
          <>
            {renderAvailableCategories()?.length > 0 && (
              <div className="mb-4 text-[16px] font-semibold">
                Please select one or more services from the categories below.
              </div>
            )}
            <div
              className="items-center mb-6 w-[340px] relative "
              ref={servicesList}
            >
              <button
                className="w-fit min-w-[340px] border border-black py-2 pl-4 pr-16 rounded-3xl cursor-pointer "
                ref={servicesList}
                onClick={() => setShowOptions(!showOptions)}
              >
                {selectedServiceCategory?.name || "Select a Category"}
                <div className="absolute top-[17px] right-[13px]">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 18 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L9 9L17 1"
                      stroke="#525252"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </button>
              {showOptions && (
                <div className="absolute w-[340px] top-[calc(100%+6px)] max-h-[300px] overflow-auto left-0 p-0 bg-white border rounded ">
                  {renderAvailableCategories()?.length > 0
                    ? renderAvailableCategories()?.map((service) => {
                      return (
                        <button
                          key={service.id}
                          className="w-full leading-[18px] px-[16px] py-2 border-b hover:bg-gray-100 cursor-pointer duration-75"
                          onClick={() => {
                            setSelectedServiceCategory(service);
                            setShowOptions(false);
                          }}
                        >
                          {service.name}
                        </button>
                      );
                    })
                    : "No Categories Available"}
                </div>
              )}
            </div>
          </>
        )}
        {selectedServiceCategory && (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
            {getServicesByCategory()?.map((service) => {
              return (
                <button
                  className={`flex flex-col border-[1px]  rounded-lg p-4 ${noClubbableServicesIds.includes(service.id)
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                    } ${selectedServicesIds?.includes(service.id)
                      ? `${accountConfig.theme}-theme card-active primary-color`
                      : "border-[#E2E8F0]"
                    }`}
                  onClick={() => selectService(service)}
                  key={service.id}
                  title={`${noClubbableServicesIds.includes(service.id)
                      ? "Cannot be booked with current services selection"
                      : ""
                    }`}
                >
                  <div className="mb-6 text-[18px] font-semibold break-word">{service.name}</div>
                  <div className="mb-6">
                    <div
                      tabIndex="-1"
                      role="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        selectService(service);
                      }}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                        selectService(service);
                      }}
                      className="break-word"
                      dangerouslySetInnerHTML={{
                        __html: `${service.description?.slice(0, 93)} ${service.description?.length > 93 ? "..." : ""
                          }`,
                      }}
                    />
                    {service.description?.length > 93 && (
                      <div
                        tabIndex="-1"
                        role="button"
                        className={`${accountConfig.theme}-theme primary-color font-semibold float-left`}
                        onClick={(event) => selectReadMore(event, service)}
                        onKeyDown={(event) => selectReadMore(event, service)}
                      >
                        Read More
                      </div>
                    )}
                  </div>
                  <div className="flex items-center font-semibold mt-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "#334155" }}
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-clock"
                      viewBox="0 0 16 16"
                      stroke="black"
                      strokeWidth="0.3"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                    <span>{service.duration} minutes</span>
                  </div>
                </button>
              );
            })}
          </div>
        )}
        {
          <div className="grid grid-cols-2 w-full sm:w-1/2 gap-4">
            <ButtonSecondary text="Previous" onClick={() => handlePrevious()} />
            {selectedServices?.length > 0 && (
              <ButtonPrimary text="Next" onClick={() => handleNext()} />
            )}
          </div>
        }
      </div>
      {showReadMoreModal && (
        <ReadMoreModal
          readMore={readMore}
          setShowReadMoreModal={setShowReadMoreModal}
        />
      )}
      {showDeposit && (
        <DepositModal deposit={deposit} setShowDeposit={setShowDeposit} />
      )}
      {isCallClinicModalOpen && (
        <CallToScheduleServiceModal
          onClose={() => setIsCallClinicModalOpen(false)}
        />
      )}
    </div>
  );
};

export default observer(StepTwo);
