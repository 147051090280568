import React from 'react'

 const CommonModal = ({ children }) => {
  return (
    <div className='fixed top-0 left-0 bg-black/30 w-full h-screen flex justify-center items-start z-[999] overflow-y-scroll pb-[400px] pt-[30px]'>
        <div className={`max-w-[90%] bg-[white] rounded-2xl p-6 flex flex-col items-center lg:max-w-[700px]`}>
          {children}
        </div>
    </div>
  )
}

export default CommonModal;
