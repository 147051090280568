import CommonModal from 'component/Common/CommonModal';
import React from 'react'
import ButtonPrimary from "../../Common/ButtonPrimary";

const BookingErrorModal = (props) => {

    const { bookingError, setShowBookingErrorModal } = props;

    return (
        <CommonModal>
            <div className='mb-4'>
                <svg className='w-[50px] h-[50px]' viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <path d="M512 853.333333a341.333333 341.333333 0 1 1 341.333333-341.333333 341.333333 341.333333 0 0 1-341.333333 341.333333z m0-768a426.666667 426.666667 0 1 0 426.666667 426.666667A426.666667 426.666667 0 0 0 512 85.333333z m110.506667 256L512 451.84 401.493333 341.333333 341.333333 401.493333 451.84 512 341.333333 622.506667 401.493333 682.666667 512 572.16 622.506667 682.666667 682.666667 622.506667 572.16 512 682.666667 401.493333z" fill="#B00020" />
                </svg>
            </div>
            <div className='mb-6 text-center text-2xl font-semibold'>We Were Unable To Book Your Appointment</div>
            <div className='overflow-auto mb-4'>
                <p>{bookingError}</p>
            </div>
            <div className='flex w-full justify-end'>
                <ButtonPrimary text='Ok'  onClick={() => setShowBookingErrorModal(false)} />
            </div>
        </CommonModal>
    )
}

export default BookingErrorModal;
